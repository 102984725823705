.Administration-Container {
  padding: 20px;
  color: #273837 !important;
}
.Administration-Search-Container {
  display: flex;
  flex-direction: row;
}
.Administration-Table-Container {
  margin-top: 20px;
}
#Administration-Submit {
  background-color: #ec4d37;
  border-color: #ec4d37;
}
