.frequent-container {
  display: flex;
  flex: 1;
  filter: drop-shadow(0px 4px 4px #273837);
  background-color: #cce0df;
}
.report-container {
  display: flex;
  flex: 1;
  padding-top: 50px;
}
.left-container {
  height: 100%;
  width: 40%;
  padding: 10px;
}

.word-cloud {
  height: 80%;
}

.report-list-container {
  height: 100%;
  width: 60%;
  padding: 10px;
}

.title-txt {
  height: 10%;
  padding: 2%;
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  color: #273837;
  font-weight: bold;
  text-align: center;
}
.title-page-txt {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 25px;
  color: #273837;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 10px;
  left: 15px;
  left: 0;
  right: 0;
  text-align: center;
}

.btn-refresh-container {
  height: 10%;
  text-align: center;
}
#btn-refresh-list {
  background-color: #ec4d37;
  font-family: "Montserrat", sans-serif;
}

.loading-centered-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.loading-centered {
  height: 200px;
  width: 200px;
}
