.M-SigUp-Container {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 100vh;
  color: #273837 !important;
}
.M-SignUp-Form {
  background-color: #cce0df;
  width: 50vh;
  flex-wrap: wrap;
  border-radius: 5%;
  padding: 4%;
  box-shadow: 1px 2px 2px 1px grey;
}
.M-SignUp {
  padding: 5%;
}
.eye {
  cursor: pointer;
}
.M-SignUp-Password-Description {
  padding: 5px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.M-SignUp-Password-Text {
  font-size: 13px;
  font-weight: bold;
}
.M-SignUp-Password-Text.active {
  color: #426b69;
}
.M-SignUp-Password-Text.deactive {
  color: red;
}
.M-SignUp-Deny-Text {
  font-size: 20px;
  text-align: center;
  font-family: "Raleway", sans-serif;
}
#M-SignUp-Submit {
  background-color: #ec4d37;
  border-color: #ec4d37;
  justify-self: flex-end;
}
.M-SignUp-Container-Submit {
  display: flex;
  justify-content: flex-end;
}
