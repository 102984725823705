.App {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.Header {
  display: flex;
  height: 180px;
}
.Logo-Container {
  background-color: #ffffff;
  min-width: 270px;
  height: 180px;
  text-align: center;
}
.Banner-Frame {
  background-color: #426b69;
  flex: 1;
}
.Title {
  color: #ffffff;
  padding-left: 15%;
  padding-top: 6%;
  font-size: 40px;
  font-family: "Raleway", sans-serif;
}
.View-Container {
  display: flex;
  flex: 1;
  height: 100vh;
  padding: 20px;
}
.Container {
  display: flex;
  flex: 1;
}

.root-alert-container {
  position: absolute;
  bottom: 5%;
  left: calc(270px + 10%);
  right: 10%;
}
