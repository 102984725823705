.image-modal-styles {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-styles {
  border-style: none;
  max-height: 95%;
  max-width: 95%;
}
