.btn-tab:hover {
  transform: translateY(5px) scale(1);
  box-shadow: 0 10px 5px 0 rgba(90, 95, 101, 0.2);
}

.btn-tab {
  color: #ffffff;
  /* border-color: #8ce3af; */
  border-width: thin;
  /* width: 25%; */
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-weight: 600;
}

.pressed-btn {
  background-color: #426b69;
}

.unpressed-btn {
  background-color: #426b6970;
}
