.container-recipes {
  height: 100%;
}
.options-tab-container {
  height: 30px;
}

.grid-container-all-col {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: calc(100% - (30px + 30px + 30px));
}

.grid-container-one-col {
  display: grid;
  gap: 1rem;
}

.grid-first-column {
  grid-template-rows: minmax(1rem, 20rem) minmax(
      1rem,
      calc(100vh - (30px + 30px + 30px + 3rem + 40px + 20rem))
    );
}
.grid-second-third-column {
  grid-template-rows: 4rem calc(
      100vh - (30px + 30px + 30px + 3rem + 40px + 4rem)
    );
}
.column-with-padding-left {
  padding: 1rem 0.5rem 1rem 1rem;
}

.column-with-padding-right {
  padding: 1rem 1rem 1rem 0.5rem;
}

.column-with-only-vertical-padding {
  padding: 1rem 0.5rem 1rem 0.5rem;
}

.header-modal-title {
  display: flex;
  justify-content: center;
  height: 30px;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-size: 26px;
  font-weight: 700;
  color: #ffffff;
  background: #426b69;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #273837;
  box-shadow: 0px 3.73333px 3.73333px rgba(0, 0, 0, 0.25);
  /* border-radius: 30px 30px 0px 0px; */
}

.card-title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  color: #273837;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 0px;
}

.card-text {
  text-align: center;
  height: 1.48em;
  color: #27383780;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 900;
}

.card-body {
  height: calc(100% - (26px + 10px));
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.card-body::-webkit-scrollbar {
  display: none;
}

.list-item-text {
  color: #273837;
  text-transform: lowercase;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
}

.list-item-text::first-letter {
  text-transform: uppercase;
}

.card-container {
  padding: 1rem;
}

.btn-group-container-modal {
  font-size: 22px;
  height: 30px;
  display: flex;
  flex-direction: row;
}

.btn-container-modal {
  line-height: 0%;
  width: 50%;
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-weight: 800;
}
.btn-container-go-back {
  line-height: 0%;
  width: 100%;
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-weight: 800;
}
.btn-cancel {
  color: #ec4d37;
  background-color: #ffffff;
  /* border-bottom-left-radius: 30px; */
  border-color: #273837;
}

.btn-save {
  color: #ffffff;
  background-color: #426b69;
  /* border-bottom-right-radius: 30px; */
  border-color: #273837;
}

.btn-cancel:hover {
  background-color: #ec4d37;
  transform: translateY(-2px) scale(1);
  box-shadow: 0 10px 5px 0 rgba(90, 95, 101, 0.2);
  color: #ffffff;
}

.btn-save:hover {
  background-color: #273837;
  transform: translateY(-2px) scale(1);
  box-shadow: 0 10px 5px 0 rgba(90, 95, 101, 0.2);
  color: #ffffff;
}

.specific-title-padding {
  margin-top: 10px;
}
