.Nav-Container {
  background-color: #426b69;
  max-width: 270px;
  min-height: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.Nav-Container-List-Item {
  display: flex;
  flex-direction: column;
  padding-left: 0;
}

#btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

#btn-logout {
  background-color: #ec4d37;
  font-family: "Raleway", sans-serif;
}

.logo-ritsii-container {
  background-color: #ffffff;
  width: 260px;
  height: 180px;
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
}
