.recipe-admin-container {
  display: flex;
  flex: 1;
  filter: drop-shadow(0px 4px 4px #273837);
  background-color: #cce0df;
  flex-direction: column;
}

header {
  /* display: flex; */
  height: 100px;
  padding: 20px 20px 0px 20px;
  /*  align-items: center;
  justify-content: center; */
}

.alert-container {
  position: absolute;
  bottom: 5%;
  left: 10%;
  right: 10%;
}

.dialog-container-center-horizontal {
  width: calc(100% - 270px);
  left: calc(270px / 2);
  margin: 0 auto;
}
