.action-column-item-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.action-column-item {
  margin: 0;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  /* font-size: 16px; */
  text-decoration-line: underline;
  color: #ec4d37;
}

.filter-fields-container {
  padding: 0px 20px;
  margin: 20px 0px;
}

.search-title {
  font-family: "Montserrat";
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  color: #426b69;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}