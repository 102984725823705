.Nav-Item.active {
  background-color: #ffffff;
}
.Nav-Item {
  display: flex;
  height: 70px;
  width: 270px;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}
.Nav-Item-Name {
  color: #ffffff;
  font-family: "Raleway", sans-serif;
  font-size: 22px;
  margin-left: 15px;
}
.Nav-Item-Name.active {
  color: #426b69;
}
.Item-List {
  list-style: none;
}
