.reasons-report-container {
  height: 100%;
}

.reason-card-list {
  padding: 10px;
  list-style-type: none;
  list-style-position: inside;
  overflow: hidden;
  overflow-y: scroll;
  height: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.reason-card-list::-webkit-scrollbar {
  display: none;
}

.reason-card {
  background-color: #cef3dd;
  padding: 2%;
  margin-bottom: 2%;
  filter: drop-shadow(0px 4px 4px #273837);
  border-radius: 10px;
  border-width: 1px;
  border-color: #273837;
}

.txt-description {
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  color: #273837;
}

.datetime-txt {
  font-family: "Raleway", sans-serif;
  font-style: italic;
  font-size: 12px;
  color: #ec4d37;
}
