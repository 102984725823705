.search-filter-container {
  padding: 0 20px;
  font-family: "Montserrat", sans-serif;
}

#btn-filter-list {
  background-color: #426b69;
  font-family: "Montserrat", sans-serif;
}

.search-button-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 25%;
}

.search-button-container-left {
  padding-left: 5px;
}

.search-button-container-right {
  padding-right: 5px;
}

.search-title-container-inside-recipe {
  padding: 0.5rem 0 0.5rem 0;
}

.date-picker-container-inside-recipe {
  display: flex;
  flex-direction: row;
}

.date-picker-container-inside-recipe-left {
  padding: 0.5rem 0.5rem 0.5rem 0;
  width: 25%;
}

.date-picker-container-inside-recipe-right {
  padding: 0.5rem;
  width: 25%;
}