.frequent-container {
  display: flex;
  flex: 1;
  filter: drop-shadow(0px 4px 4px #273837);
  background-color: #cce0df;
}

.dashboard-column {
  display: flex;
  flex: 1;
}

.dashboard-square {
  flex: 1;
  padding: 30px;
}

.apexcharts-tooltip {
  color: #273837;
}

.dashboard-card {
  background-color: #cef3dd;
  filter: drop-shadow(0px 4px 4px #273837);
  border-radius: 10px;
  border-width: 1px;
  border-color: #273837;
}