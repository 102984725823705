.input {
    font-family: 'Montserrat', sans-serif;
    width: 60%;
    position: relative;
    left: 20%;
    border-radius: 10px;
    border-width: 1px;
    border-color: #273837;
    height: 40px;
    text-align: center;
}

.container-login {
    height: 100vh;
    background-color: #CEF3DD;

}

.container-form {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
}

#form-container {
    display: flex;
    flex-direction: column;
    height: 333px;
    width: 580px;
    background-color: #8CE3AF;
    justify-content: space-around;
    border-radius: 10px;
    filter: drop-shadow(0px 4px 4px #273837);
    z-index: 2;
}

#txt {
    align-self: center;
    font-family: 'Raleway', sans-serif;
    font-size: 25px;
    color: #273837;
}


.btn-container {
    display: flex;
    height: 60px;
    justify-content: center;
}

#btn-login {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    background-color: #EC4D37;
    border-radius: 15px;
    border-color: #EC4D37;
    filter: drop-shadow(0px 4px 4px #273837);
    height: 45px;
    padding-left: 2%;
    padding-right: 2%;
}

#error-msg {
    align-self: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 15px;
    color: #ff0000;
}

#left-vector-login {
    position: absolute;
    align-self: flex-end;
    top: 0;
}

#right-vector-login {
    position: absolute;
    align-self: flex-start;
    bottom: 0;
}

#logo-login {
    z-index: 1;
    min-height: 0%;
}

.txt-and-label-container {
    display: flex;
    flex-direction: column;
}

.alert-space {
    display: flex;
    height: 200px;
    align-items: center;
}